import { Component, Output, EventEmitter, Input, ViewEncapsulation } from '@angular/core';

import { OrderProductComponent } from '../order-product.component';

import { OrderProduct } from '../../../../order/models/order.model';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'li[uh-editable-order-product-row]',
  templateUrl: './editable-order-product-row.component.html',
  styleUrls: ['./editable-order-product-row.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditableOrderProductRowComponent extends OrderProductComponent {
  @Input() uhIsEditable = true;

  @Output() uhIncrementProduct = new EventEmitter<OrderProduct>();
  @Output() uhDecrementProduct = new EventEmitter<OrderProduct>();
  @Output() uhRemoveFromCart = new EventEmitter<OrderProduct>();

  incrementProductProduct() {
    this.uhIncrementProduct.emit(this.uhOrderProduct);
  }

  decrementProductProduct() {
    this.uhDecrementProduct.emit(this.uhOrderProduct);
  }

  removeFromCart() {
    this.uhRemoveFromCart.emit(this.uhOrderProduct);
  }
}
