import { Component } from '@angular/core';

@Component({
  template: `
    <h1 class="text-center m-5">
      Ops, we could'nt find this page.<br />
      <small>Please, check the address and try again.</small>
    </h1>
  `
  // styles: ['']
})
export class PageNotFoundComponent {}
