import { Component } from '@angular/core';

@Component({
  selector: 'uh-obtrusive-loader',
  styleUrls: ['./obtrusive-loader.component.scss'],
  template: `
    <div class="obtrusive-loader">
      <div class="preloader-video unhide"></div>
      <h1>Aguarde enquanto processamos o seu pedido...</h1>
    </div>
  `
})
export class ObtrusiveLoaderComponent { }
