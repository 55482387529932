import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { OriginDataService } from './origin-data.service';

@Injectable({
    providedIn: 'root'
})
export class NavigationHelpService {
    constructor(private ods: OriginDataService, private r: Router) { }

    navigateWithOriginData(path: string) {
        const origindata = this.ods.getOriginData();
        const updatedhash = this.ods.originDataToHash(origindata);

        this.r.navigateByUrl(
            this.r.createUrlTree(
                [path], { queryParams: { _c: updatedhash } }
            )
        );
    }
}
