import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

import { SigninDialogComponent } from './signin-dialog/signin-dialog.component';

import { SigninModule } from '../signin/signin.module';

@NgModule({
    declarations: [SigninDialogComponent],
    imports: [SharedModule, SigninModule],
    exports: [SigninDialogComponent],
    entryComponents: [SigninDialogComponent],
})
export class DialogsModule { }
