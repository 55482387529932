import { Component } from '@angular/core';

@Component({
  selector: 'uh-feedback-message-body',
  template: '<p><ng-content></ng-content></p>',
  styles: [`
    p {
      font-family: 'Geomanist-Regular';
      margin-bottom: 30px;
      line-height: 30px;
    }
  `],
})
export class FeedbackMessageBodyComponent {}
