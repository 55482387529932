import { NgModule, ModuleWithProviders } from '@angular/core';

import {
  AuthGuardService,
  RouteDataService,
  CepService,
  OriginGuardService,
  TranslationService,
  UserService
} from './services';

import { QuickCheckoutGuard } from './services/quick-checkout.guard';
import { NavigationHelpService } from './services/navigation-helper.service';

@NgModule()
export class UtilitiesModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: UtilitiesModule,
      providers: [
        AuthGuardService,
        RouteDataService,
        CepService,
        OriginGuardService,
        TranslationService,
        UserService,
        QuickCheckoutGuard,
        NavigationHelpService
      ]
    };
  }
}
