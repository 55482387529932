import { Directive, forwardRef, Attribute } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[ccExpiration][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CCExpirationDirective),
      multi: true
    }
  ]
})
export class CCExpirationDirective implements Validator {
  constructor(@Attribute('ccExpRelated') public ccExpRelated: string) {}

  validateYear(control: AbstractControl, today: Date) {
    if (control) {
      const yearCheckRegx = new RegExp('^[0-9][0-9][0-9][0-9]$');

      if (!yearCheckRegx.test(control.value + '')) {
        return false;
      }

      return parseInt(control.value, 10) >= today.getFullYear();
    }
  }

  validateMonth(control: AbstractControl, yearControl: AbstractControl, today: Date) {
    if (control) {
      if (yearControl && !isNaN(yearControl.value)) {
        if (
          yearControl.value === today.getFullYear() &&
          control.value < today.getMonth()
        ) {
          return false;
        }
      }

      const controlValue = parseInt(control.value, 10);

      return controlValue > 0 && controlValue <= 12;
    }

    return false;
  }

  validate(c: AbstractControl): { [key: string]: any } {
    // self value (e.g. expiration month | year)
    const c1 = c;

    // control value (e.g. expiration year | month)
    const c2 = c.parent.get(this.ccExpRelated);

    const yearControl = this.ccExpRelated === 'ccExpMonth' ? c1 : c2;
    const monthControl = this.ccExpRelated === 'ccExpYear' ? c1 : c2;

    const today = new Date();
    const validYear = this.validateYear(yearControl, today);
    const validMonth = this.validateMonth(monthControl, yearControl, today);

    // value not equal
    if (this.ccExpRelated === 'ccExpMonth') {
      if (!validYear) {
        return { expirationYear: false };
      } else {
        if (yearControl.errors && yearControl.errors.expirationYear) {
          delete yearControl.errors['expirationYear'];
          if (!Object.keys(yearControl.errors).length) {
            yearControl.setErrors(null);
          }
        }
      }
    }

    if (this.ccExpRelated === 'ccExpYear') {
      if (!validMonth) {
        return { expirationMonth: false };
      } else {
        if (monthControl.errors && monthControl.errors.expirationMonth) {
          delete monthControl.errors['expirationMonth'];
          if (!Object.keys(monthControl.errors).length) {
            monthControl.setErrors(null);
          }
        }
      }
    }

    return null;
  }
}
