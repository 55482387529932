import { Component, Input, OnDestroy } from '@angular/core';

import { Routes, Route } from '@angular/router';

import { OrderService } from 'app/order/services/order.service';
import { Subscription } from 'rxjs';
import { Order } from 'app/order/models/order.model';

@Component({
  selector: 'uh-checkout-wizard',
  templateUrl: './checkout-wizard.component.html',
  styleUrls: ['./checkout-wizard.component.scss']
})
export class CheckoutWizardComponent {
  @Input() routes: Routes;
  @Input() activeRoutePath: string;

  order: Order = this.orderService.order;

  constructor(private orderService: OrderService) {}

  preventNavigation(evt: Event) {
    evt.preventDefault();
    evt.stopPropagation();
  }
}
