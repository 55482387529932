export interface IBillingInfo {
  country: string;
  zipcode: string;
  state: string;
  city: string;
  line1: string;
  line2: string;
  number: string;
  neighborhood: string;
  fullname: string;
  email: string;
  documenttype: string;
  document: string;
}

export class BillingPostData implements IBillingInfo {
  country: any = 'BR';
  zipcode = '';
  state = '';
  city = '';
  line1 = '';
  line2 = '';
  number = '';
  neighborhood = '';
  fullname = '';
  email = '';
  documenttype: any = 'cpf';
  document = '';

  constructor(bfm?: IBillingInfo) {
    if (bfm != null) {
      this.country = bfm.country || this.country;
      this.zipcode = bfm.zipcode || this.zipcode;
      this.state = bfm.state || this.state;
      this.city = bfm.city || this.city;
      this.line1 = bfm.line1 || this.line1;
      this.line2 = bfm.line2 || this.line2;
      this.number = bfm.number || this.number;
      this.neighborhood = bfm.neighborhood || this.neighborhood;
      this.fullname = bfm.fullname || this.fullname;
      this.email = bfm.email || this.email;
      this.documenttype = bfm.documenttype || this.documenttype;
      this.document = bfm.document || this.document;
    }
  }
}

export class BillingFormModel extends BillingPostData {
  emailconfirm = '';
  username = '';

  constructor(bi?: IBillingInfo) {
    super(bi);
    this.emailconfirm = bi ? bi.email : '';
  }
}
