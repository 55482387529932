import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { SigninService } from '../../signin/signin.service';
import { OriginDataService } from './origin-data.service';

import { of } from 'rxjs';
import { NavigationHelpService } from './navigation-helper.service';

@Injectable({
  providedIn: 'root'
})
export class QuickCheckoutGuard implements CanActivate {

  constructor(
    private ss: SigninService,
    private nhs: NavigationHelpService
  ) { }

  canActivate() {
    if (this.ss.isAnonymousUser) {
      this.nhs.navigateWithOriginData('/order/billing');
      return false;
    }

    return true;
  }
}
