import { Component } from '@angular/core';
import {
  Routes,
  Router,
  Route,
  ActivatedRoute,
  NavigationEnd
} from '@angular/router';

import { animRoutes, fadeInOut } from './animations';
import { CheckoutStoreService } from 'app/order/services/checkout-store.service';

import { CheckoutStoreModel } from 'app/order/models';
import { filter, tap } from 'rxjs/operators';
import { RouteDataService } from 'app/utilities/services/route-data.service';
import { OriginDataService } from 'app/utilities/services/origin-data.service';
import { NavigationHelpService } from 'app/utilities/services/navigation-helper.service';
import { GoogleTagManagerService } from 'app/utilities/services/google-tag-manager.service';
import { OrderService } from 'app/order/services/order.service';
import { DiscountCouponsService } from 'app/order/services/discount-coupons.service';

@Component({
  templateUrl: './checkout-layout.component.html',
  styleUrls: ['./checkout-layout.component.scss'],
  animations: [animRoutes, fadeInOut] // register the animation
})
export class CheckoutLayoutComponent {
  store: CheckoutStoreModel;

  wizardRoutes: Routes = [];
  routeIdx = 0;

  get isValidStoreData() {
    //OBSERVE IF TERMS KEY ARRAY ARE VALID
    if (this.currentRoute.data.storekeys != null && this.currentRoute.data.storekeys != undefined && this.currentRoute.data.storekeys.length > 0) {
      return this.store && this.store[this.currentRoute.data.storekeys[0]].valid && this.store[this.currentRoute.data.storekeys[1]].valid;

    }
    if (this.currentRoute.data.storekey != null) {
      return this.store && this.store[this.currentRoute.data.storekey].valid;
    }

    return false;
  }

  get isLastStep() {
    const [lastroute] = this.routes.slice(-1);
    return this.currentRoute.data.routeIdx === lastroute.data.routeIdx;
  }

  get isFirstStep() {
    const [lastroute] = this.routes;
    return this.currentRoute.data.routeIdx === lastroute.data.routeIdx;
  }

  get parentRoute() {
    return this.route.snapshot;
  }

  get currentRoute() {
    return this.route.snapshot.firstChild.firstChild;
  }

  get routes() {
    return this.route.firstChild.routeConfig['_loadedConfig'].routes;
  }

  get origindata() {
    return this.ods.getOriginData();
  }

  get paymenttype(): any {
    return this.currentRoute.parent.url[0].path;
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cs: CheckoutStoreService,
    private ods: OriginDataService,
    private nhs: NavigationHelpService,
    private gtms: GoogleTagManagerService,
    private os: OrderService,
    private dcs: DiscountCouponsService
  ) {
    this.initWizardRoutes(this.routes);
    this.cs.store$.subscribe(store => (this.store = store));

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        tap(() => (this.routeIdx = this.currentRoute.data.routeIdx))
      )
      .subscribe();

      console.log(this.ods.getOriginData())
    console.log(this.dcs.activeCouponcode);
    const {couponcode } = this.ods.getOriginData();
    const couponCode =  couponcode ? couponcode : this.dcs.activeCouponcode ;
    this.gtms.dispatchBeginCheckout(this.os.order.orderproducts[0], couponCode, this.ods.getOriginData().origin);
  }


  initWizardRoutes(routes) {
    routes.forEach(r => {
      const origin = this.origindata.origin;

      if (r.data && r.data.wizard) {
        if (r.data.exclude_origins && r.data.accepted_origins) {
          throw new Error(
            'You can\'t use "exlude_origins" and "accepted_origins" options togheter. Choose one.'
          );
        }

        if (r.data.exclude_origins) {
          this.excludeOrigins(r, r.data.exclude_origins, origin);
        } else if (r.data.accepted_origins) {
          this.acceptedOrigins(r, r.data.accepted_origins, origin);
        } else {
          this.wizardRoutes = [...this.wizardRoutes, r];
        }
      }

      if (r.children && r.children.length > 0) {
        this.initWizardRoutes(r.children);
      }
    });
  }

  excludeOrigins(route, origins = [], current_origin) {
    const exclude = origins.some(o => o === current_origin);

    if (!exclude) {
      this.wizardRoutes = [...this.wizardRoutes, route];
    }
  }

  acceptedOrigins(route, origins = [], current_origin) {
    const accepted = origins.some(o => o === current_origin);

    if (accepted) {
      this.wizardRoutes = [...this.wizardRoutes, route];
    }
  }

  navigateTo(dir = 'first') {
    const cRouteIndex = this.wizardRoutes.findIndex(
      wr => wr.data.routeIdx === this.routeIdx
    );

    switch (dir) {
      case 'left':
        const prevRoute = this.wizardRoutes[cRouteIndex - 1];
        if (prevRoute) {
          this.nhs.navigateWithOriginData(this.getCheckoutStepUrl(prevRoute));
        }
        break;

      case 'right':
        const nextRoute = this.wizardRoutes[cRouteIndex + 1];
        if (nextRoute) {
          this.nhs.navigateWithOriginData(this.getCheckoutStepUrl(nextRoute));
        }
        break;

      default:
        const firstRoute = this.wizardRoutes[0];
        this.nhs.navigateWithOriginData(this.getCheckoutStepUrl(firstRoute));
    }
  }

  getCheckoutStepUrl(nextroute: Route): string {
    const routepath = nextroute.path;
    const routearr = [this.paymenttype];

    if (routepath !== '') {
      routearr.unshift('');
      routearr.push(routepath);
    }

    return routearr.length > 1 ? routearr.join('/') : routearr[0];
  }
}
