import { Directive, forwardRef, OnInit, ElementRef, HostListener } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';

import { DocumentValidatorService } from '../services/document-validator.service';
import { fromEvent, interval } from 'rxjs';
import { map, debounce, switchMap } from 'rxjs/operators';

@Directive({
  selector: '[uhDOC][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => UHDOCDirective),
      multi: true
    }
  ]
})
export class UHDOCDirective implements Validator, OnInit {
  private el: HTMLInputElement;

  constructor(private elementRef: ElementRef, private docv: DocumentValidatorService) {
    this.el = this.elementRef.nativeElement;
  }

  ngOnInit() {
    this.el.value = this.formatDocument(this.el.value);
  }

  validate(c: AbstractControl) {
    const value = c.value != null ? c.value : '';
    const validdoc = this.docv.valida_cpf_cnpj(value);

    if (!validdoc) {
      this.el.value = this.removeFormatting(value);
      return { uhDoc: false };
    }

    this.el.value = this.formatDocument(value);
    return null;
  }

  formatDocument(value: string): string {
    const formated = this.docv.formata_cpf_cnpj(value);

    if (!formated) {
      return value;
    }

    return <string>formated;
  }

  removeFormatting(value: string) {
    return value.replace(/[^0-9]/g, '');
  }
}
