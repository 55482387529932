import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';

import { ApiService, UserManagementService } from 'uh-core';

type FilterOperation = 'EQ' | 'NEQ' | 'IS' | 'ISNOT' | 'LT' | 'GT' | 'LIKE' | 'NOTLIKE' | 'ILIKE' | 'NOTILIKE';
type OrderByOperation = 'ASC' | 'DESC';

export interface GraphqlOrderByOption<T> {
    f: keyof T;
    o: OrderByOperation;
}

export interface GraphqlFilterItemOption {
    op: FilterOperation;
    v: string | number | boolean;
}

export interface GraphqlFilterItem {
    [key: string]: GraphqlFilterItemOption;
}

export type GraphqlFilters = GraphqlFilterItem[];

export interface GraphqlQueryArguments<T> {
    page?: number;
    perpage?: number;
    filters?: GraphqlFilterItem[];
    orderby?: GraphqlOrderByOption<T>;
    [k: string]: any;
}

export class BaseGraphqlQuery<T> {
    constructor(public query: string, public variables: T) {}
}

export class BaseGraphqlQueryBuilder<T> extends BaseGraphqlQuery<GraphqlQueryArguments<T>> {
    constructor(query: string, args: GraphqlQueryArguments<T>) {
        const variables: GraphqlQueryArguments<T> = {};

        Object.keys(args).forEach((argsKey: string) => {
            switch (argsKey) {
                case 'filters':
                    variables.filters = args.filters != null ? args.filters : [];
                    break;

                case 'orderby':
                    variables.orderby = args.orderby;
                    break;

                default:
                    variables[argsKey] = args[argsKey];
                    break;
            }
        });

        super(query, variables);
    }
}


@Injectable({
    providedIn: 'root'
})
export class GraphqlClientApiService {
    constructor(private apis: ApiService) {}

    graphqlRequest(query: string, variables: GraphqlQueryArguments<any> = {}, auth = false) {
        const gqldata = new BaseGraphqlQueryBuilder(query, variables);

        let entityName: string;

        try {
            const regex = new RegExp('^\W*(?<schema>\w+)?\W+(?<object>\w+)|^\W*\{\W*(\k<object>)', 'g');
            const matches = query.match(regex);
            entityName = matches[2];
        } catch (error) { }

        const endpoint = environment.apipath + (entityName != null ? `/graphql?e=${entityName}` : `/graphql`);
        return this.apis.post(endpoint, gqldata, auth, true).pipe(map(res => res.data));
    }

    graphqlUpdate(mutation: string, variables: any = {}, auth = false) {
        const gqldata =  new BaseGraphqlQuery<any>(mutation, variables);
        return this.apis.post('/api/graphql', gqldata, auth, true).pipe(map(res => res.data));
    }
}
