import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { MainGuardService } from './utilities/services/main-guard.service';
import { MenuContentComponent } from './_layouts/menu-content/menu-content.component';
import { CheckoutLayoutComponent } from './_layouts/checkout-layout/checkout-layout.component';

import { LayoutsModule } from './_layouts/layouts.module';
import { TestGenerateOrderComponent } from './test-generate-order.component';
import { SubscriptionCanActivateGuard } from './subscription/services/subscription.guard';

const routes: Routes = [
  {
    path: '',
    component: CheckoutLayoutComponent,
    canActivate: [MainGuardService],
    children: [
      {
        path: 'order',
        loadChildren: './order/order.module#OrderModule'
      }
    ]
  },
  {
    path: '',
    component: CheckoutLayoutComponent,
    canActivate: [SubscriptionCanActivateGuard],
    children: [
      {
        path: 'subscription',
        loadChildren: './subscription/subscription.module#SubscriptionModule'
      }
    ]
  },
  {
    path: '',
    component: MenuContentComponent,
    canActivate: [MainGuardService],
    children: [
      {
        path: 'feedback',
        loadChildren: './feedback/feedback.module#FeedbackModule'
      }
    ]
  },
  {
    path: 'XUH19X/generate-order',
    component: TestGenerateOrderComponent
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always' }), LayoutsModule],
  exports: [RouterModule, LayoutsModule]
})
export class AppRoutingModule { }
