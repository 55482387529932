import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ObtrusiveLoaderService } from './service/obtrusive-loader.service';
import { ObtrusiveLoaderDirective } from './directive/obtrusive-loader.directive';
import { ObtrusiveLoaderComponent } from './component/obtrusive-loader.component';

@NgModule({
  imports: [CommonModule],
  declarations: [ObtrusiveLoaderDirective, ObtrusiveLoaderComponent],
  entryComponents: [ObtrusiveLoaderComponent],
  exports: [ObtrusiveLoaderDirective]
})
export class ObtrusiveLoaderModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: ObtrusiveLoaderModule,
      providers: [ObtrusiveLoaderService]
    };
  }
}
