import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

export interface SigninFormModel {
  username: string;
  password: string;
}

@Component({
  selector: 'uh-signin-form',
  templateUrl: './signin-form.component.html',
  styleUrls: ['./signin-form.component.scss']
})
export class SigninFormComponent implements OnInit {
  validationError = {
    invalid: false,
    reason: 'Usuário e/ou senha inválidos, tente novamente'
  };

  @Input() uhEmail = null;
  @Output() uhOnSubmit = new EventEmitter<SigninFormModel>();

  form: FormGroup;

  constructor() {
    this.form = new FormGroup({
      username: new FormControl(null, Validators.required),
      password: new FormControl(null, Validators.required)
    });
  }

  ngOnInit() {
    this.form.controls['username'].setValue(this.uhEmail);
  }

  submitForm(fd: SigninFormModel) {
    this.uhOnSubmit.emit(fd);
  }

  clearForm() {
    this.uhEmail = null;
    this.form.controls['username'].reset(null);
    this.form.controls['password'].reset(null);
  }
}
