import { Injectable } from '@angular/core';

import { map, take, publishLast, refCount } from 'rxjs/operators';

import { ApiService, ApiResponse } from 'uh-core';

import {
  SubscriptionPlanDict,
  NewSubscriptionDict,
  SubscriptionHandlersDict,
  SubscriptionsDict,
  RequestSubscriptionPayload,
  DiscountCouponDict
} from '../models/subscription-dicts.model';

import { UHSubscription } from '../models/subscription.model';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionApiService {
  constructor(private api: ApiService) { }

  // READ
  getRootPlans() {
    const url = `/subscriptionplan/getrootplans`;
    return this.api.get(url).pipe(map(res => res as ApiResponse<SubscriptionPlanDict>));
  }

  getNewSubscriptionByRootPlan({ subsplanid, subsplanpriceid }) {
    const url = `/subscription/newsubscriptionfromrootplan/${subsplanid}/${subsplanpriceid}`;
    return this.api.get(url, true).pipe(
      map((res: ApiResponse<NewSubscriptionDict>) => {
        const subs = { ...res.dict.subscription, subscriptionplan: res.dict.subscriptionplan };
        return subs as UHSubscription;
      })
    );
  }

  getSubscriptionFormData() {
    const url = `/subscription/getsubscriptionformdata`;
    return this.api.get(url, true).pipe(map(res => res as ApiResponse<SubscriptionHandlersDict>));
  }

  getSubscriptionsByUid() {
    const url = `/subscription/getsubscriptionsbyuid`;
    return this.api.get(url, true).pipe(
      publishLast(),
      refCount(),
      map(res => res as ApiResponse<SubscriptionsDict>)
    );
  }

  getDiscountCoupon(subscriptionid: number, couponcode: string) {
    const url = `/subscriptionplan/getdiscountcoupon/${subscriptionid}/${couponcode}`;
    return this.api.get(url, true).pipe(
      map(res => res as ApiResponse<DiscountCouponDict>),
      publishLast(),
      refCount(),
    );
  }

  updateSubscriptionMembersQuantity(sid: number, qty: number) {
    const url = `/subscription/updatesubscriptionquantity/${sid}/${qty}`;
    return this.api.get(url, true).pipe(
      publishLast(),
      refCount(),
    );
  }

  // applySubscriptionDiscountCoupon(subscriptionid: number, couponcode: string) {
  //   const url = `/subscriptionplan/getdiscountcoupon/${subscriptionid}/${couponcode}`;
  //   return this.api.get(url, true).pipe(
  //     map(res => res as ApiResponse<DiscountCouponDict>),
  //     publishLast(),
  //     refCount(),
  //   );
  // }

  setSubscriptionHandler(subscriptionid: number, subshandlerid: number) {
    const url = `/subscription/setsubscriptionhandler/${subscriptionid}/${subshandlerid}`;
    return this.api.get(url, true).pipe(take(1));
  }

  checkActivatedSubscription(subscriptionid: number) {
    const url = `/subscription/checkifactivated/${subscriptionid}`;
    return this.api.get(url, true);
  }

  requestSubscriptionActivation(subscriptionid: number, payload: RequestSubscriptionPayload) {
    const url = `/subscription/requestactivation/${subscriptionid}`;
    return this.api.post(url, payload, true);
  }
}

