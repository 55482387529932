import { Component } from '@angular/core';
import { OrderProductComponent } from '../order-product.component';

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[uh-simple-order-product-row]',
  templateUrl: './simple-order-product-row.component.html',
  styleUrls: ['./simple-order-product-row.component.scss']
})
export class SimpleOrderProductRowComponent extends OrderProductComponent {}
