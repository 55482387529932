import { Component, OnInit } from '@angular/core';
import { SigninFormModel } from '../signin-form/signin-form.component';
import { SigninService } from '../signin.service';
import { OriginDataService } from 'app/utilities/services/origin-data.service';

@Component({
  templateUrl: './signin.component.html',
})
export class SigninComponent {
  error = {
    show: false,
    reason: 'Usuário e/ou senha inválidos.',
  };

  get origindata() {
    return this.ors.getOriginData();
  }

  constructor(
    private ss: SigninService,
    private ors: OriginDataService
  ) { }

  onSubmitLogin({ username, password }: SigninFormModel) {
    const orderid = this.origindata.orderid;

    this.ss
      .login(username, password, orderid)
      .subscribe(
        res => this.ss.navigateWithOriginData('order'),
        err => {
          console.log(err);
          this.error.show = true;
        }
      );
  }

  continueWithoutLogin() {
    this.ors.setOriginDataProp('isQuickCheckout', true);
    this.ss.navigateWithOriginData('order');
  }
}
