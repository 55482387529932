import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { tap, takeWhile } from 'rxjs/operators';

import { SubscriptionApiService } from './subscription-api.service';
import { UHSubscription, Discountcoupon } from '../models/subscription.model';
import { PaymentService } from 'app/payment/services/payment.service';

declare const dataLayer;

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  private readonly _subscriptions$ = new BehaviorSubject<UHSubscription[]>([]);
  readonly subscriptions$ = this._subscriptions$.asObservable().pipe(
    // HOOK | GTM.dataLayer updater
    tap(subs => dataLayer.push({ totalprice: subs.length > 0 ? subs[0].TotalWithDiscount : undefined }))
  );

  private readonly _discountcoupon$ = new BehaviorSubject<Discountcoupon>(null);
  readonly discountcoupon$ = this._discountcoupon$.asObservable();

  get subscriptions() {
    return this._subscriptions$.getValue();
  }
  set subscriptions(val) {
    this._subscriptions$.next(val);
  }

  get discountcoupon() {
    return this._discountcoupon$.getValue();
  }
  set discountcoupon(val) {
    this._discountcoupon$.next(val);
  }

  constructor(private sapis: SubscriptionApiService) { }

  getNewSubscription(simpleSubscription) {
    return this.sapis.getNewSubscriptionByRootPlan(simpleSubscription).pipe(
      tap(() => {
        // Update user subscriptions list
        this.getUserSubscriptions().subscribe(
          res => this._subscriptions$.next(res.dict.subscriptions)
        );
      })
    );
  }

  getUserSubscriptions() {
    const obs = this.sapis.getSubscriptionsByUid();
    obs.subscribe(res => this._subscriptions$.next(res.dict.subscriptions));
    return obs;
  }

  applySubscriptionDiscountCoupon(subscriptionid: number, couponcode: string) {
    const obs = this.sapis.getDiscountCoupon(subscriptionid, couponcode);

    obs.subscribe({
      next: res => {
        this.discountcoupon = res.dict.discountcoupon;
        this.getUserSubscriptions();
      },
      error: () => this.discountcoupon = null
    });

    return obs;
  }

  updateSubscriptionMembersQuantity(type: 'increment' | 'decrement', qty: number) {
    const subscriptions = this._subscriptions$.value;
    const reducedSubscriptions = this.reducer(type, qty);
    const asid = subscriptions[0].subscriptionid;

    const obs = this.sapis.updateSubscriptionMembersQuantity(asid, reducedSubscriptions[0].quantity);

    obs.subscribe({
      next: res => {
        const TotalWithDiscount = res.dict.TotalWithDiscount;
        const quantity = reducedSubscriptions[0].quantity;
        const subscription = { ...subscriptions[0], TotalWithDiscount, quantity };

        const updatedSubscriptions = [...subscriptions];
        updatedSubscriptions[0] = subscription;

        this._subscriptions$.next(updatedSubscriptions);
      },
      error: () => this._subscriptions$.next(subscriptions)
    });

    return obs;
  }

  reducer(action: string, payload: any) {
    const subscriptions = [...this._subscriptions$.value];
    const activesubscription: UHSubscription = subscriptions[0];

    switch (action) {
      case 'decrement': {
        activesubscription.quantity = activesubscription.quantity > 1 ? activesubscription.quantity - 1 : 1;
      } break;

      case 'increment': {
        activesubscription.quantity = activesubscription.quantity + 1;
      } break;
    }

    subscriptions[0] = activesubscription;

    return subscriptions;
  }
}
