import { Component, OnInit } from '@angular/core';
import { SigninService } from '../signin.service';

@Component({
  selector: 'uh-signin-forgot-password',
  templateUrl: './signin-forgot-password.component.html',
  styleUrls: ['./signin-forgot-password.component.scss']
})
export class SigninForgotPasswordComponent implements OnInit {
  loading = false;

  constructor(private ss: SigninService) { }

  ngOnInit(): void { }


  sendForgotEmail(emailforgotpass) {
    this.loading = true;

    const isvalidemail = this.validateEmail(emailforgotpass);

    if (!isvalidemail) {
      this.loading = false;

      // Set email field ERROR
      // Invalid email, please use another

      return;
    }

    this.ss.forgotPassword(emailforgotpass).subscribe(
      function (res) {
        // Successfully sent reset pass email
      },
      function (err) {
        // Set email field ERROR
        // Problem with data, SERVER ERROR
      }
    );
  }

  private validateEmail(email) {
    const re = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,6})?$/;
    return email !== '' && re.test(email);
  }
}
