import { Component, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Order, OrderProduct } from '../../../order/models/order.model';
import { OrderService } from 'app/order/services/order.service';
import { Subscription } from 'rxjs';
import { RouteDataService } from 'app/utilities/services/route-data.service';
import { switchMap, tap } from 'rxjs/operators';
import { DiscountCouponsService } from 'app/order/services/discount-coupons.service';

@Component({
  selector: 'uh-order-resume',
  templateUrl: './order-resume.component.html',
  styleUrls: ['./order-resume.component.scss']
})
export class OrderResumeComponent implements OnDestroy {
  order: Order;
  subs: Subscription;
  origin: string;
  uhmProduct: OrderProduct;

  totalPrice = 0;
  totalWithDiscount = 0;

  observeDiscount: boolean;

  @Output() uhGoToCart = new EventEmitter();

  forceDiscountCupomApply = false;

  get withdiscountcoupon() {
    return this.order.orderproducts.some(
      op => op.discountcoupon != null && op.discountcouponid > 0
    );
  }

  get ismastersbundle() {
    return this.origin !== 'unhidemasters:bundle';
  }

  get ischeckingoutorderstate() {
    return this.order.orderstate.internalname === 'checking_out';
  }

  constructor(private os: OrderService, private rd: RouteDataService,
    public dcs: DiscountCouponsService
  ) {
    this.subs = this.os.order$.subscribe(order => {
      this.forceDiscountCupomApply = this.os.hasNotAppliedDiscount;
      this.order = order;
      this.totalPrice = this.os.totalPrice;
      console.log(this.os.totalWithDiscount)
      this.totalWithDiscount = this.os.hasNotAppliedDiscount ? this.os.notAppliedDiscount : this.os.totalWithDiscount ;
      this.uhmProduct = this.order.orderproducts.find(
        op =>
          op.categories.some(c => c.internalname === 'unhidemasters') &&
          op.categories.some(c => c.internalname === 'project')
      ) || this.order.orderproducts[0];
    });

    this.origin = this.rd.origin;


  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  unwrapText(text, unwrapOnly = null) {
    if (unwrapOnly === null) {
      return text.replace(/<[^>]*>/g, '');
    }

    const temp: HTMLElement = document.createElement('div');
    temp.innerHTML = text;

    return temp.firstElementChild.innerHTML;
  }

  goToCartStep() {
    this.uhGoToCart.emit();
  }
}
