import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';

import { CheckoutStoreService } from '../../order/services/checkout-store.service';
import { OrderService } from '../../order/services/order.service';
import { AuthGuardService } from './auth-guard.service';
import { OriginDataService } from './origin-data.service';

import { switchMap, catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MainGuardService implements CanActivate {
  constructor(
    private store: CheckoutStoreService,
    private ags: AuthGuardService,
    private os: OrderService,
    private ors: OriginDataService
  ) { }

  canActivate(route: ActivatedRouteSnapshot) {
    this.store.clear(true);

    const resolveAuth = this.ags.canActivate(route);

    if (this.os.order != null) {
      return resolveAuth.pipe(map(() => true));
    }

    return resolveAuth.pipe(
      switchMap(() => {
        const orderid = this.ors.getOriginData().orderid;
        return this.os.getOrderById(orderid, true);
      }),
      catchError(err => {
        // console.log('ERROR', err);
        this.ors.clearOriginData();
        return of(false);
      }),
      map(() => true)
    );
  }
}
