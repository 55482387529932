import { Injectable } from '@angular/core';
import { LocalstorageService, AuthorizationService } from 'uh-core';
import { environment } from 'environments/environment';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

export interface OriginData {
  origin: string;
  token: string;
  orderid?: number;
  subscriptionid?: number;
  isQuickCheckout?: boolean;
  couponcode?: string;
}

@Injectable({
  providedIn: 'root'
})
export class OriginDataService {
  private originDataStorageKey = environment.storage.keys.uhorigin;

  private set origindata(data: OriginData) {
    const jsondata = JSON.stringify(data);
    this.ls.setItem(this.originDataStorageKey, jsondata);
  }

  private get origindata(): OriginData {
    const originData = this.ls.getItem(this.originDataStorageKey);
    return originData != null ? JSON.parse(originData) : null;
  }

  constructor(
    private ls: LocalstorageService,
    private as: AuthorizationService,
    private loc: Location,
    private router: Router
  ) { }

  setOriginData(data: OriginData | any = null): void {
    if (data != null) {
      if (this.origindata == null) {
        this.origindata = { origin: null, token: null };
      }

      this.origindata = { ...this.origindata, ...data };
    }
  }

  getOriginData(propkey: string = null): OriginData {
    if (propkey != null) {
      return this.origindata[propkey] != null ? this.origindata[propkey] : null;
    }

    return this.origindata != null ? this.origindata : null;
  }

  setOriginDataProp(key, value) {
    const origindata = this.getOriginData();
    const neworigindata = origindata != null ? origindata : {};
    neworigindata[key] = value;

    this.setOriginData(neworigindata);

    return neworigindata;
  }

  clearOriginData(): void {
    this.ls.removeItem(this.originDataStorageKey);
  }

  originHashToObject(hash: string): OriginData {
    const jsondata = window.atob(hash.replace(/(%2f)/gi, '/').replace(/(%3d)/gi, '='));
    const data = JSON.parse(jsondata);
    return data;
  }

  originDataToHash(data: OriginData): string {
    const jsondata = JSON.stringify(data);
    const hash = window.btoa(jsondata);
    return hash;
  }

  updateOriginDataFromHash(hash: string): OriginData {
    const origindata = this.originHashToObject(hash);
    this.setOriginData(origindata);
    return origindata;
  }

  syncOriginDataToken() {
    const token = this.as.getAuthToken();
    const neworigindata = { ...this.origindata, token };
    this.setOriginData(neworigindata);
    this.updateQueryStringHash(neworigindata);
  }

  updateQueryStringHash(origindata) {
    const updatedhash = this.originDataToHash(origindata);
    // TESTING - auto update query string hash on origin data token sync
    const cpath = this.loc.path().split('?')[0]; // extract only the path without the query string
    const updatedUrlTree = this.router.createUrlTree([cpath], { queryParams: { _c: updatedhash } });
    const sUrl = this.router.serializeUrl(updatedUrlTree);
    this.loc.replaceState(sUrl);
  }
}

