import { Injectable } from '@angular/core';
import { DataLayerBeginCheckoutPayload, DataLayerGA4ItemModel, DataLayerPayload } from './models/gtm.model';
import { Order, OrderProduct, Product } from 'app/order/models';

declare const dataLayer;

@Injectable({
  providedIn: 'root'
})
export class GoogleTagManagerService {
  dispatchPaymentStarted(payload: DataLayerPayload) {
    this.dispatchCustomEvent('initiate_checkout', payload);
  }

  dispatchBeginCheckout(orderProduct: OrderProduct, coupon: string, affiliation) {
    const item: DataLayerGA4ItemModel = {
      affiliation : 'affiliation',
      coupon,
      item_id: orderProduct.productid.toString(),
      item_name: orderProduct.title,
      price: Number(orderProduct.price),
      quantity: orderProduct.quantity,
      discount: 0,
    };
    const data: DataLayerBeginCheckoutPayload = {
      items: [item],
      value: Number(orderProduct.price),
      coupon,
      currency: 'BRL'
    };
    console.log('generatelead', data)
    const event = 'begin_checkout'
    dataLayer.push({event, ...data});
  }

  dispatchGenerateLead(payload: any, orderProduct: OrderProduct)  {
    const data = {
      ...payload,
      value: orderProduct.price,
      currency: 'BRL'

    }
    const event = 'generate_lead';
    dataLayer.push({event, data});
  }

  dispatchAddPaymentInfo(payload: DataLayerPayload) {
    this.dispatchCustomEvent('add_payment_info', payload);
  }
  
  dispatchPurchaseSuccess(payload: DataLayerPayload) {
    this.dispatchCustomEvent('subscription_success', payload);
  }

  dispatchPaymentFailed(payload: DataLayerPayload) {
    this.dispatchCustomEvent('payment_failed', payload);
  }

  dispatchBankSlipGenerated(payload: DataLayerPayload) {
    this.dispatchCustomEvent('bankslip_generated', payload);
  }

  dispatchSubscriptionSuccess(payload: DataLayerPayload) {
    this.dispatchCustomEvent('subscription_success', payload);
  }

  dispatchAddToCartFBPixel(payload: DataLayerPayload) {
    this.dispatchCustomEvent('fbpixel_add_to_cart', payload);
  }

  private dispatchCustomEvent(event: string, payload: DataLayerPayload) {
    // Set the event with a payload into the GTM dataLayer
    dataLayer.push({ event, ...payload });
  }
}
