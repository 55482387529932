import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { OriginDataService } from './origin-data.service';

@Injectable({
  providedIn: 'root'
})
export class OriginGuardService implements CanActivate {
  constructor(private ors: OriginDataService) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const chash = route.queryParams._c;

    if (chash) {
      const data = this.ors.updateOriginDataFromHash(chash);
      route.data = { ...route.data, ...data };
      return true;
    }

    return false;
  }
}
