import { Directive, forwardRef, Attribute } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

import { CreditCardService } from '../services/credit-card.service';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[ccCVC][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CCCVCDirective),
      multi: true
    }
  ]
})
export class CCCVCDirective implements Validator {
  constructor(
    private cc: CreditCardService,
    @Attribute('ccType') private ccType: string
  ) {}

  validate(c: AbstractControl): { [key: string]: any } {
    // self value (e.g. retype password)
    const v = c.value;

    const valid = this.cc.validateCardCVC(c.value, this.ccType);

    if (!valid) {
      return { ccCVC: false };
    }

    return null;
  }
}
