import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'uh-custom-button',
  templateUrl: 'custom-button.template.html',
  styleUrls: ['./custom-button.scss']
})
export class CustomButtonComponent {
  @Output() click = new EventEmitter();

  clickHandler($event) {
    this.click.emit($event);
  }
}
