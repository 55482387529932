import { Injectable } from '@angular/core';

import { LocalstorageService } from 'uh-core';

import {
  ParticipantFormModel,
  BillingFormModel,
  CheckoutStoreModel,
  CheckoutStoreModelItem
} from '../models';

import { CreditcardFormModel } from '../../payment/models/creditcard.model';

type CheckoutStorekey = 'acceptUsageTerms' | 'acceptPrivacyTerms' | 'billingData' | 'partakersData' | 'creditcardData';

import { BehaviorSubject } from 'rxjs';
import { UHSubscription } from 'app/subscription/models/subscription.model';

const defaultStoreState = new CheckoutStoreModel();

declare const dataLayer;

@Injectable({
  providedIn: 'root'
})
export class CheckoutStoreService {
  localStorageKey = 'uh-checkoutdata';

  private readonly _store$ = new BehaviorSubject<CheckoutStoreModel>(defaultStoreState);
  readonly store$ = this._store$.asObservable();

  constructor(private ls: LocalstorageService) {
    const storedvalue = JSON.parse(this.ls.getItem(this.localStorageKey));

    if (!storedvalue || Object.keys(storedvalue).length === 0) {
      this.ls.setItem(this.localStorageKey, JSON.stringify(this.store));
    } else {
      this._store$.next(storedvalue);
    }
  }

  get store(): CheckoutStoreModel {
    return this._store$ && this._store$.value;
  }

  get acceptUsageTerms() {
    return this.store.acceptUsageTerms;
  }

  get acceptPrivacyTerms() {
    return this.store.acceptPrivacyTerms;
  }

  set acceptUsageTerms(value: CheckoutStoreModelItem<void>) {
    this.set('acceptUsageTerms', value);
  }

  set acceptPrivacyTerms(value: CheckoutStoreModelItem<void>) {
    this.set('acceptPrivacyTerms', value);
  }

  get billingData() {
    return this.store.billingData;
  }

  set billingData(value: CheckoutStoreModelItem<BillingFormModel>) {
    this.set('billingData', value);
  }

  get partakersData() {
    return this.store.partakersData;
  }

  set partakersData(
    value: CheckoutStoreModelItem<{ [key: string]: ParticipantFormModel[] }>
  ) {
    this.set('partakersData', value);
  }

  get creditcardData() {
    return this.store.creditcardData;
  }

  set creditcardData(value: CheckoutStoreModelItem<CreditcardFormModel>) {
    this.set('creditcardData', value);
  }

  get subscription() {
    return this.store.subscription;
  }

  set subscription(val: CheckoutStoreModelItem<UHSubscription>) {
    this.set('subscription', val);
  }

  get(key: CheckoutStorekey): CheckoutStoreModelItem<any> {
    return this.store[key] || null;
  }

  set(key: string, payload: any) {
    const store = { ...this.store };
    store[key] = payload;

    // Add "Checkout Store" to GTM dataLayer
    dataLayer.push({
      billingdata: this.store.billingData.data,
      partakersdata: this.store.partakersData.data,
      installments:
        this.store.creditcardData.data &&
        this.store.creditcardData.data.installments
    });

    this._store$.next(store);
    this.ls.setItem(this.localStorageKey, JSON.stringify(store));
  }

  clear(all = false) {
    if (all) {
      this.ls.removeItem(this.localStorageKey);
      this._store$.next(defaultStoreState);
    }
  }
}
