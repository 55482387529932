export const getActivePromotionStringQuery = `
  query ActivePromotionStringQuery {
    ProjectStrings(filters: [
      {namespaceid:{op:EQ, v:10}},
      {siteid:{op:EQ, v:1}},
      {propertyid:{op:EQ, v:79}},
      {value:{op:NOTLIKE, v:"\\"NO-PROMO\\""}}
    ]) { value }
  }
`;
