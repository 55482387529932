import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderProductComponent } from './order-product.component';
import { EditableOrderProductRowComponent } from './editable-order-product-row/editable-order-product-row.component';
import { SimpleOrderProductRowComponent } from './simple-order-product-row/simple-order-product-row.component';
import { SubscriptionOrderProductRowComponent } from './subscription-order-product-row/subscription-order-product-row.component';


@NgModule({
    declarations: [
        OrderProductComponent,
        EditableOrderProductRowComponent,
        SimpleOrderProductRowComponent,
        SubscriptionOrderProductRowComponent
    ],
    imports: [CommonModule],
    exports: [
        OrderProductComponent,
        EditableOrderProductRowComponent,
        SimpleOrderProductRowComponent,
        SubscriptionOrderProductRowComponent
    ]
})
export class OrderProductModule { }
