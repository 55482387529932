import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'unwrapHTML'})
export class UnwrapHTMLPipe implements PipeTransform {
    transform(value: string): any {
        const divEl = document.createElement('div');
        divEl.innerHTML = value;
        return divEl.textContent;
    }
}
