import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MainMenuModule } from '../shared/components/main-menu/main-menu.module';

import { MenuContentComponent } from './menu-content/menu-content.component';
import { CheckoutLayoutComponent } from './checkout-layout/checkout-layout.component';
import { CheckoutWizardComponent } from './checkout-wizard/checkout-wizard.component';

import { OrderResumeModule } from '../shared/components/order-resume/order-resume.module';

import { ObtrusiveLoaderModule } from '../shared/components/obtrusive-loader/obtrusive-loader.module';
import { SharedModule } from 'app/shared/shared.module';
@NgModule({
  declarations: [MenuContentComponent, CheckoutLayoutComponent, CheckoutWizardComponent],
  imports: [CommonModule, RouterModule, MainMenuModule, SharedModule, ObtrusiveLoaderModule, OrderResumeModule],
  exports: [MenuContentComponent, CheckoutLayoutComponent],
})
export class LayoutsModule { }
