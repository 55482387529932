import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../../shared.module';

import { MainMenuComponent } from './main-menu.component';

import { DialogsModule } from '../../../dialogs/dialogs.module';

@NgModule({
  declarations: [MainMenuComponent],
  imports: [CommonModule, SharedModule, DialogsModule],
  exports: [MainMenuComponent]
})
export class MainMenuModule { }
