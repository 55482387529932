import { Component } from '@angular/core';

@Component({
  selector: 'uh-feedback-header-divider',
  styleUrls: ['./feedback-header-divider.component.scss'],
  template: `
    <div class="ico-payment">
      <ng-content></ng-content>

      <div class="bullet-wrapper">
        <div class="bullet-container">
          <div class="bullet"></div>
        </div>
      </div>
    </div>
  `
})
export class FeedbackHeaderDividerComponent { }
