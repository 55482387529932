import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { UtilitiesModule } from './utilities/utilities.module';

import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';

import { CoreModule } from 'uh-core';
import { TranslatorModule } from 'uh-translator';

import { environment } from 'environments/environment';
import { TestGenerateOrderComponent } from './test-generate-order.component';

@NgModule({
  declarations: [AppComponent, PageNotFoundComponent, TestGenerateOrderComponent],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    UtilitiesModule.forRoot(),
    CoreModule.forRoot(environment),
    TranslatorModule.forRoot(environment)
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
