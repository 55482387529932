import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Route } from '@angular/router';

import { Subscription } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { OriginDataService } from './origin-data.service';

@Injectable({
  providedIn: 'root'
})
export class RouteDataService implements OnDestroy {
  subs: Subscription;
  routedata: any;

  get exclude_origins() {
    return this.routedata['exclude_origins'];
  }

  get routeIdx() {
    return this.routedata['routeIdx'];
  }

  get isWizard() {
    return this.routedata['wizard'];
  }

  get title() {
    return this.routedata['wizardTitle'];
  }

  get mobileTitle() {
    return this.routedata['wizardMobileTitle'];
  }

  get origin() {
    return this.ods.getOriginData().origin;
  }

  get token() {
    return this.routedata['token'];
  }

  get ignorePartakers() {
    const pksRoute = this.getRouteByPath('partakers');
    return pksRoute.data.accepted_origins.every(ao => ao !== this.origin);
  }

  get orderidRoute() {
    return this.av.firstChild.firstChild;
  }

  get wizardroute() {
    return this.orderidRoute.firstChild;
  }

  constructor(private av: ActivatedRoute, private ods: OriginDataService) {
    this.subs = this.orderidRoute.data
      .pipe(
        mergeMap(rdata =>
          this.wizardroute.data.pipe(map(cdata => ({ ...rdata, ...cdata })))
        )
      )
      .subscribe(d => (this.routedata = d));
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  getOriginUrl(origin) {
    if (origin.indexOf('masters') >= 0) {
      return environment.uhmasterspath;
    }

    if (origin.indexOf('conference') >= 0) {
      return environment.uhconfpath;
    }

    if (origin.indexOf('awards') >= 0) {
      return environment.uhawardspath;
    }

    return environment.uhschoolpath + '/home';
  }

  getRouteByPath(path: string): Route {
    return this.orderidRoute.routeConfig['_loadedConfig'].routes.find(cr => cr.path === path);
  }

  getRouteByRouteidx(routeIdx: number): Route {
    return this.orderidRoute.routeConfig.children.find(
      cr => cr.data.routeIdx === routeIdx
    );
  }
}
