export const getActivePromotionCouponQuery = `
query GetPromoCouponByCode($couponcode: String!) {
  DiscountCoupon {
    all(couponCode: $couponcode, active: true, expired: false) {
      items {
        couponcode
        value
        discountcoupontype {
          internalname
        }
      }
    }
  }
}
`;