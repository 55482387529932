import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import {
  UserManagementService,
  LocalstorageService,
  AuthorizationService
} from 'uh-core';

import { UserService } from './user.service';

import { environment } from 'environments/environment';
import { OriginGuardService } from './origin-guard.service';

declare const dataLayer;
@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  constructor(
    private ums: UserManagementService,
    private ls: LocalstorageService,
    private auth: AuthorizationService,
    private userService: UserService,
    private og: OriginGuardService,
    private us: UserService
  ) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    this.clearLocalStoredData();

    // Resolve origin data
    if (!this.og.canActivate(route)) {
      return of(false);
    }

    this.auth.setAuthToken(route.data.token);

    return this.us.getAvatar().pipe(
      map(() => true)
    );
  }

  clearLocalStoredData() {
    const keys = environment.storage.keys;
    Object.keys(keys)
      .map(k => keys[k])
      .forEach(_k => this.ls.removeItem(_k));
  }
}
