import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '../shared/shared.module';

import { SigninComponent } from './signin/signin.component';
import { SigninFormComponent } from './signin-form/signin-form.component';
import { SigninForgotPasswordComponent } from './signin-forgot-password/signin-forgot-password.component';

@NgModule({
  declarations: [SigninComponent, SigninFormComponent, SigninForgotPasswordComponent],
  imports: [SharedModule, FormsModule, ReactiveFormsModule],
  exports: [SigninComponent, SigninFormComponent, FormsModule, ReactiveFormsModule, SigninForgotPasswordComponent]
})
export class SigninModule { }
