import { Directive, forwardRef, Input, Attribute } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

import { CreditCardService } from '../services/credit-card.service';

const cardPatterns = {
  amex: '0000.000000.00000',
  visa: '0000.0000.0000.0000',
  mastercard: '0000.0000.0000.0000'
};

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[ccNumber][ngModel]',
  exportAs: 'ccNumber',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CCNumberDirective),
      multi: true
    }
  ]
})
export class CCNumberDirective implements Validator {
  @Input() cardtypes: string[] = null;

  value: string = null;
  cardpattern: string;

  constructor(
    private cc: CreditCardService,
    @Attribute('cctypes') public cctypes: string
  ) {}

  validate(c: AbstractControl): { [key: string]: any } {
    const cctypes: string[] = this.cctypesToArray(this.cctypes);

    // self value (e.g. retype password)
    const v = c.value;

    const card = this.cc.validateCardNumber(v);

    if (cctypes.length === 0 || !cctypes.some(t => t === card.type)) {
      card.valid = false;
      this.value = null; // NO CARD TYPE
      this.cardpattern = '0000'; // MATCHES ONE TO THREE CHAR AND FORBID TYPING
    } else {
      // Set brand name
      this.value = card.type;
      this.cardpattern = cardPatterns[card.type];
    }

    if (!card.valid) {
      return { ccNumber: 'Invalid card number!' };
    }

    return null;
  }

  private cctypesToArray(cctypesstring: string): string[] {
    if (cctypesstring != null && cctypesstring !== '') {
      return cctypesstring
        .replace(' ', '')
        .split(',')
        .map(t => t.trim());
    }

    return [];
  }

  private cardPatterns(cctype) {
    if (cctype != null) {
      return cardPatterns[cctype];
    }

    return '09';
  }
}
