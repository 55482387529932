import { Component } from '@angular/core';

@Component({
  selector: 'uh-feedback-message-title',
  template: '<h3><ng-content></ng-content></h3>',
  styles: [`
    h3 {
      font-family: 'Geomanist-Medium';
      text-transform: uppercase;
      margin-bottom: 40px;
    }
  `],
})
export class FeedbackMessageTitleComponent {}
