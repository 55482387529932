import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

export interface PriceWithDiscountData {
  price: string;
  quantity: number;
  total: string;
  prefix?: string;
}

@Component({
  selector: 'uh-price-with-discount',
  styleUrls: ['./price-with-discount.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <strong *ngIf="expected > total" class="mr-2 ml-2">
      <s> R$ {{ expected | number: '1.2-2' }} </s>
    </strong>

    <strong> R$ {{ total | number: '1.2-2' }} </strong>
  `
})
export class PriceWithDiscountComponent {
  expected = 0;
  total = 0;

  @Input()
  set data(value: PriceWithDiscountData) {
    this.total = parseInt(value.total, 10);
    this.expected = parseInt(value.price, 10) * value.quantity;
  }
}
