import {
  trigger,
  animate,
  transition,
  style,
  query,
  group,
  state
} from '@angular/animations';

const slideLeft = [
  query(
    ':leave',
    style({
      position: 'absolute',
      left: 0,
      right: 0,
      transform: 'translate3d(0%,0,0)',
      opacity: 1
    }),
    { optional: true }
  ),
  query(
    ':enter',
    style({
      position: 'absolute',
      left: 0,
      right: 0,
      transform: 'translate3d(-500px,0,0)',
      opacity: 0
    }),
    { optional: true }
  ),
  group([
    query(
      ':leave',
      group([
        animate(
          '500ms cubic-bezier(.35,0,.25,1)',
          style({ transform: 'translate3d(500px,0,0)' })
        ),
        animate('250ms cubic-bezier(.35,0,.25,1)', style({ opacity: 0 }))
      ]),
      { optional: true }
    ),

    query(
      ':enter',
      group([
        animate(
          '500ms cubic-bezier(.35,0,.25,1)',
          style({ transform: 'translate3d(0%,0,0)' })
        ),
        animate('750ms cubic-bezier(.35,0,.25,1)', style({ opacity: 1 }))
      ]),
      { optional: true }
    )
  ])
];

const slideRight = [
  query(
    ':leave',
    style({
      position: 'absolute',
      left: 0,
      right: 0,
      transform: 'translate3d(0%,0,0)',
      opacity: 1
    }),
    { optional: true }
  ),
  query(
    ':enter',
    style({
      position: 'absolute',
      left: 0,
      right: 0,
      transform: 'translate3d(500px,0,0)',
      opacity: 0
    }),
    { optional: true }
  ),

  group([
    query(
      ':leave',
      group([
        animate(
          '500ms cubic-bezier(.35,0,.25,1)',
          style({ transform: 'translate3d(-500px,0,0)' })
        ),
        animate('250ms cubic-bezier(.35,0,.25,1)', style({ opacity: 0 }))
      ]),
      { optional: true }
    ),

    query(
      ':enter',
      group([
        animate(
          '500ms cubic-bezier(.35,0,.25,1)',
          style({ transform: 'translate3d(0%,0,0)' })
        ),
        animate('750ms cubic-bezier(.35,0,.25,1)', style({ opacity: 1 }))
      ]),
      { optional: true }
    )
  ])
];

export const animRoutes = trigger('animRoutes', [
  transition(':increment', slideRight),
  transition(':decrement', slideLeft)
]);

export const fadeInOut = trigger('fadeInOut', [
  transition(':enter', [style({ opacity: 0 }), animate('500ms')]),
  transition(':leave', [animate(250, style({ opacity: 0 }))]),
  state('*', style({ opacity: 1 }))
]);
