export const getProductPromotions = `
query getProductCoupons($productid: Int!) {
  Product {
    item(productId: $productid) {
      internalname
      discountcoupons {
        discountcouponid
        name
        couponcode
      }
    }
  }
}
`;