export interface PurchaseBase {
  ueid: string;
  uid: number;
  purchasedata: string | PurchaseDataModel;
  purchaseid: number;
  orderproductid: number;
  datepurchased: string;
  quantityused: number;
  datelastused: string;
  datecreated: string;
  dateprinted: string;
  active: boolean;
  enabled: boolean;
}

export interface PurchaseGetData extends PurchaseBase {
  purchasedata: string;
}

export interface PurchaseDataModel {
  users: IParticipantInfo[];
}

export interface IParticipantInfo {
  fullname: string;
  email: string;
  documenttype: string;
  document: string;
  genre: string;
  specialneeds: string;
  withspecialneeds: boolean;
  usebillinginfo: boolean;
  orderproductid: number;
}

export class ParticipantPostData implements IParticipantInfo {
  fullname = '';
  email = '';
  documenttype = 'cpf';
  document = '';
  genre = null;
  specialneeds = '';
  withspecialneeds = false;
  usebillinginfo = false;
  orderproductid = null;

  constructor(p?: IParticipantInfo) {
    if (p != null) {
      this.orderproductid = p.orderproductid;
      this.fullname = p.fullname || this.fullname;
      this.email = p.email || this.email;
      this.documenttype = p.documenttype || this.documenttype;
      this.document = p.document || this.document;
      this.genre = p.genre || this.genre;
      this.specialneeds = p.specialneeds || this.specialneeds;
      this.withspecialneeds = p.withspecialneeds || this.withspecialneeds;
      this.usebillinginfo = p.usebillinginfo || this.usebillinginfo;
    }
  }
}

export class ParticipantFormModel extends ParticipantPostData
  implements IParticipantInfo {
  emailconfirm = '';

  constructor(p?: IParticipantInfo) {
    super(p);
    this.emailconfirm = p ? p.email : this.emailconfirm;
  }
}

export interface IOrderProductCustomData {
  customdata: string;
}

export interface IOrderProductCustomDataModel {
  users: IParticipantInfo[];
}

export class PurchaseCustomData implements IOrderProductCustomData {
  customdata: string;

  constructor(ps: ParticipantFormModel[]) {
    const users = ps.map(p => new ParticipantPostData(p));
    this.customdata = JSON.stringify({ users });
  }
}
