import { Directive, HostListener, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[uhStickything]'
})
export class StickythingDirective {
  private element: HTMLElement;
  private windowPos: number;
  private windowsWidth: number;

  constructor(elementRef: ElementRef, private renderer: Renderer2) {
    this.element = elementRef.nativeElement;
  }

  @HostListener('window:scroll', []) onWindowScroll() {
    this.windowsWidth = window.innerWidth;
    this.windowPos = window.pageYOffset;

    if (this.windowPos > 335 && this.windowsWidth > 992) {
      this.renderer.addClass(this.element, 'fixed');
    } else {
      this.renderer.removeClass(this.element, 'fixed');
    }
  }
}
