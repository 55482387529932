import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { take, takeWhile, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ObtrusiveLoaderService {
  private waitlist: string[] = [];

  isWaiting = new BehaviorSubject<boolean>(this.waitlist.length > 0);

  wait(actionid: string) {
    this.waitlist = [...this.waitlist, actionid];
    this.isWaiting.next(true);
  }

  done(actionid: string) {
    this.waitlist = this.waitlist.filter(aid => aid !== actionid);
    this.isWaiting.next(false);
  }
}
