import { Injectable } from '@angular/core';

import { take, switchMap, catchError, map, tap } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';

import { OrderService } from './order.service';

import { OriginDataService } from '../../utilities/services/origin-data.service';
import { GraphqlClientApiService } from 'app/utilities/services/graphql-client-api.service';
import { getActivePromotionStringQuery } from 'app/utilities/graphql/get_active_promotion_string.query';
import { getActivePromotionCouponQuery } from 'app/utilities/graphql/get_active_promottion_coupon.query';
import { getProductPromotions } from 'app/utilities/graphql/get_product_promotions.query';

@Injectable({
  providedIn: 'root'
})
export class DiscountCouponsService {
  hasActivePromo = false;
  activeCouponcode = '';

  constructor(
    private ods: OriginDataService,
    private orderService: OrderService,
    private gcapis: GraphqlClientApiService,
  ) { }

  get origin() {
    return this.ods.getOriginData().origin;
  }


  applyCouponToOrder(couponcode: string): Observable<any> {
    return this.orderService.requestAndUpdateOrder(
      'GET',
      `/order/adddiscountcoupontoorder/${this.orderService.order.orderid
      }/${couponcode.toUpperCase()}`,
      true
    );
  }

  clearCouponFromOrder(): Observable<any> {
    return this.orderService.requestAndUpdateOrder(
      'GET',
      `/order/cleardiscountcouponfromorder/${this.orderService.order.orderid}`,
      true
    );
  }

  checkAutoApplicableCupons(): Observable<any> {
    return this.clearCouponFromOrder().pipe(
      // ACTIVE PROMOTION
      switchMap(() => this.checkActivePromotion()),
      switchMap(couponcode => this.applyCouponToOrder(couponcode).pipe(
        map(() => couponcode)),
      ),
      switchMap(couponcode => {
        if (couponcode) {
          return this.getPromoInfo(couponcode).pipe(
            tap(promotioncoupon => {
              this.orderService.configCouponDiscountWithoutApply(promotioncoupon)
            }),
            map((e) => {
              return true
            })
          )
        }
      }),
      // OTHER COUPONS
      catchError(res => {
        return this.applyCouponToOrder('LOTE3402019');
      }),
      catchError(res => {
        return this.applyCouponToOrder('UNHIDEINSPIRATION50');
      }),
      catchError(err => {
        return this.applyCouponToOrder('SUBSCRIBER3002019');
      }),
      catchError(err => {
        return this.applyCouponToOrder('NEUROMANCER102019');
      }),
      catchError(err => {
        return this.applyCouponToOrder('NEUROMANCER402019');
      }),
      catchError(err => {
        return this.applyCouponToOrder('uhalphauser50');
      }),
      catchError(err => {
        return this.applyCouponToOrder('AWARDS1002019');
      }),
      take(1),

    );
  }

  handlePromoByInfinityCode() {
    const obs = of('123').pipe(
      map(() => {
        if (this.ods.getOriginData().couponcode && this.ods.getOriginData().couponcode !== undefined) {
          this.activeCouponcode = this.ods.getOriginData().couponcode
          this.hasActivePromo = true
          return {
            couponcode: this.activeCouponcode,
            type: 'string'
          }
        } else {
          return throwError('None Active Promotion')
        }
      })
    )
    return obs;
  }

  checkActivePromotion() {
    const obs = this.gcapis.graphqlRequest(getActivePromotionStringQuery).pipe(
      switchMap(data => {
        return (
          !data.ProjectStrings || data.ProjectStrings.length === 0
            ? this.handlePromoByInfinityCode()
            : of(data)
        )
      }),
      map(data => {
        if (data && data.type !== undefined && data.type === 'string') {
          return data.couponcode;
        }
        if (this.ods.getOriginData().couponcode) {
          return this.ods.getOriginData().couponcode;
        } else {
          const tmpEl = document.createElement('div');
          tmpEl.innerHTML = JSON.parse(data.ProjectStrings[0].value);
          return tmpEl.textContent;
        }

      })
    );

    obs.subscribe({
      next: data => {
        this.hasActivePromo = true;
        if (this.ods.getOriginData().couponcode && this.ods.getOriginData().couponcode !== undefined) {
          this.activeCouponcode = this.ods.getOriginData().couponcode
        } else {
          this.activeCouponcode = data
        }
      },
      error: () => {
        if (this.ods.getOriginData() && this.ods.getOriginData().couponcode !== undefined) {
          this.activeCouponcode = this.ods.getOriginData().couponcode
          return this.hasActivePromo = true
        } else {
          return this.hasActivePromo = false

        }
      }
    });

    return obs;
  }

  getPromoInfo(couponcode: string) {
    const query = getActivePromotionCouponQuery;
    const variables = {
      couponcode,
      today: (new Date()).toISOString()
    }
    this.gcapis.graphqlRequest(query, variables).subscribe();
    return this.gcapis.graphqlRequest(query, variables).pipe(
      catchError(err => throwError('No Cupom Code')),
      map(res => res.DiscountCoupon.all.items[0])
    );
  }

  checkProductHasCoupon() {
    const query = getProductPromotions;
    const variables = {
      productid: this.orderService.order.orderproducts[0].productid
    }
    return this.checkActivePromotion().pipe(
      switchMap(couponcode => {
        return this.hasActivePromo ? this.gcapis.graphqlRequest(query, variables) : of(null)
      }),
      map(res => res ? res.Product.item.discountcoupons : null),
      map(discountcoupons => discountcoupons ? discountcoupons.some(cp => cp.couponcode.toLowerCase() == this.activeCouponcode.toLowerCase()) : false),
      switchMap(hasCouponcode => hasCouponcode ? this.getPromoInfo(this.activeCouponcode.toUpperCase()) : of(null)),
      tap(this.orderService.configCouponDiscountWithoutApply.bind(this.orderService)));
  }


}

