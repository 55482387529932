import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService, LocalstorageService } from 'uh-core';

@Injectable()
export class TranslationService {
  listeners = [];

  constructor(private api: ApiService) {}

  getLangFormData(): Observable<any> {
    return this.api.get('/user/getlanguagesformdata', true).pipe(map(res => res.json()));
  }
}
