import {
  Directive,
  ViewContainerRef,
  ComponentFactoryResolver,
  AfterViewInit,
  ComponentRef
} from '@angular/core';

import { ObtrusiveLoaderComponent } from '../component/obtrusive-loader.component';

import { ObtrusiveLoaderService } from '../service/obtrusive-loader.service';

@Directive({
  selector: '[uhObtrusiveLoader]'
})
export class ObtrusiveLoaderDirective implements AfterViewInit {
  componentRef: ComponentRef<ObtrusiveLoaderComponent>;

  constructor(
    private target: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private oblService: ObtrusiveLoaderService
  ) {}

  ngAfterViewInit() {
    this.oblService.isWaiting.subscribe(
      iswating => (iswating ? this.createComponent() : this.destroyComponent()),
      err => console.log(err)
    );
  }

  createComponent() {
    const factory = this.componentFactoryResolver.resolveComponentFactory(
      ObtrusiveLoaderComponent
    );

    this.componentRef = this.target.createComponent(factory);
  }

  destroyComponent() {
    if (this.componentRef != null) {
      this.componentRef.destroy();
    }
  }
}
