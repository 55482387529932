import { Component, HostListener } from '@angular/core';
import { DiscountCouponsService } from './order/services/discount-coupons.service';

declare const dataLayer;

@Component({
  selector: 'uh-root',
  template: `
    <div class="app-wrapper"><router-outlet></router-outlet></div>
  `
})
export class AppComponent {
  title = 'UNHIDE Checkout';

  hasActivePromo = false;

  constructor(private dcs: DiscountCouponsService) {
    this.dcs.checkActivePromotion().subscribe();
  }

  @HostListener('window:unload')
  triggerGTMCustomEvent() {
    dataLayer.push({ event: 'endOfNavigation' });
  }
}
