import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OrderResumeComponent } from './order-resume.component';
import { OrderProductModule } from '../order-product/order-product.module';
import { PaymentModule } from '../../../payment/payment.module';

@NgModule({
    declarations: [OrderResumeComponent],
    imports: [CommonModule, OrderProductModule, PaymentModule],
    exports: [OrderResumeComponent],
})
export class OrderResumeModule { }