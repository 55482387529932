import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'uh-feedback-header-icon',
  template: '<i class="ico-{{ type }}"></i>',
  styleUrls: ['./feedback-header-icon.component.scss']
})
export class FeedbackHeaderIconComponent {
  @Input() type;
}
