import { BillingFormModel, ParticipantFormModel } from '.';
import { CreditcardFormModel } from '../../payment/models/creditcard.model';
import { UHSubscription } from 'app/subscription/models/subscription.model';

export interface OriginConfigData {
  user: {
    fullname: string;
    alias: string;
    username: string;
    avatar_url: string;
  };
  token: string;
  origin: string;
}

export interface CheckoutStoreModelItem<T> {
  data: T;
  valid: boolean;
}

export class CheckoutStoreModel {
  acceptUsageTerms: CheckoutStoreModelItem<void> = {
    data: null,
    valid: false
  };  
  acceptPrivacyTerms: CheckoutStoreModelItem<void> = {
    data: null,
    valid: false
  };
  billingData: CheckoutStoreModelItem<BillingFormModel> = {
    data: null,
    valid: false
  };

  partakersData: CheckoutStoreModelItem<{
    [key: string]: ParticipantFormModel[];
  }> = {
    data: null,
    valid: false
  };

  creditcardData: CheckoutStoreModelItem<CreditcardFormModel> = {
    data: null,
    valid: false
  };

  subscription: CheckoutStoreModelItem<UHSubscription> = {
    data: null,
    valid: false
  };
}

export const documentFieldTypesMapping = {
  cpf: {
    placeholder: '999.999.999-99',
    mask: '000.000.000-00',
    pattern: '^[0-9]+$'
  },
  cnpj: {
    placeholder: '99.999.999/9999-99',
    mask: '00.000.000/0000-00',
    pattern: '^[0-9]+$'
  }
};
