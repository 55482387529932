import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from 'app/shared/shared.module';

import {
  CCCVCDirective,
  CCNumberDirective,
  CCExpirationDirective,
  UHDOCDirective,
  UHEqualityDirective
} from './directives';

import { DiscountCouponComponent } from './components/discount-coupon/discount-coupon.component';

import { CreditcardFormComponent } from './components/creditcard-form/creditcard-form.component';
import { BillingFormComponent } from './components/billing-form/billing-form.component';
import { BillingInfoComponent } from './components/billing-info/billing-info.component';

@NgModule({
  imports: [SharedModule, ReactiveFormsModule, FormsModule],
  declarations: [
    CCCVCDirective,
    CCNumberDirective,
    CCExpirationDirective,
    UHDOCDirective,
    UHEqualityDirective,
    DiscountCouponComponent,
    CreditcardFormComponent,
    BillingFormComponent,
    BillingInfoComponent
  ],
  exports: [
    CCCVCDirective,
    CCNumberDirective,
    CCExpirationDirective,
    UHDOCDirective,
    UHEqualityDirective,
    DiscountCouponComponent,
    CreditcardFormComponent,
    BillingFormComponent,
    BillingInfoComponent
  ]
})
export class PaymentModule {}
