import { Component, Output, EventEmitter } from '@angular/core';

import { first, map, tap, filter } from 'rxjs/operators';

import { SubscriptionService } from 'app/subscription/services/subscription.service';
import { Observable } from 'rxjs';
import { Discountcoupon, UHSubscription } from 'app/subscription/models/subscription.model';

@Component({
  selector: 'uh-subscription-resume',
  templateUrl: './subscription-resume.component.html',
  styleUrls: ['./subscription-resume.component.scss']
})
export class SubscriptionResumeComponent {
  subscription$: Observable<UHSubscription>;
  discountcoupon$: Observable<Discountcoupon>;
  withDiscountCoupon = false;

  priceData = null;

  @Output() uhGoToCart = new EventEmitter();

  constructor(private ss: SubscriptionService) {
    this.subscription$ = this.ss.subscriptions$.pipe(filter(subs => subs.length > 0), map(subs => subs[0]));

    this.discountcoupon$ = this.ss.discountcoupon$.pipe(
      tap(dc => {
        this.withDiscountCoupon = dc != null;
        this.setPriceData(this.ss.subscriptions[0], dc);
      })
    );
  }

  setPriceData(subscription: UHSubscription, dicountCoupon: Discountcoupon) {
    this.priceData = {
      prefix: 'R$',
      price: subscription.price.price,
      quantity: subscription.quantity,
      total: dicountCoupon != null ? dicountCoupon.discount : subscription.TotalWithDiscount
    };
  }

  getDescriptionAsText(description: string): string {
    if (description === null || typeof description === 'undefined') {
      return;
    }

    const divEl = document.createElement('div');
    divEl.innerHTML = description;
    return divEl.textContent;
  }

  goToCartStep() {
    this.uhGoToCart.emit();
  }
}
