import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { StickythingDirective } from './directives/stickything.directive';

import { ObtrusiveLoaderModule } from './components/obtrusive-loader/obtrusive-loader.module';

import { NgxMaskModule } from 'ngx-mask';

import {
  MatExpansionModule,
  MatRadioModule,
  MatDatepickerModule,
  MatFormFieldModule,
  MatNativeDateModule,
  MatInputModule,
  MatDialogModule
} from '@angular/material';

import { UserAvatarComponent } from './components/user-avatar/user-avatar.component';
import { CustomButtonComponent } from './components/custom-button/custom-button.component';

import { FeedbackHeaderDividerComponent } from './components/feedback/feedback-header-divider/feedback-header-divider.component';
import { FeedbackHeaderIconComponent } from './components/feedback/feedback-header-icon/feedback-header-icon.component';
import { FeedbackMessageTitleComponent } from './components/feedback/feedback-message-title/feedback-message-title.component';
import { FeedbackMessageBodyComponent } from './components/feedback/feedback-message-body/feedback-message-body.component';
import { DiscountCouponFormComponent } from './components/discount-coupon-form/discount-coupon-form.component';
import { UnwrapHTMLPipe } from './pipes/unwrap-html.pipe';
import { PriceWithDiscountComponent } from './components/price-with-discount/price-with-discount.component';
import { SubscriptionResumeComponent } from './components/subscription-resume/subscription-resume.component';

@NgModule({
  declarations: [
    StickythingDirective,
    UserAvatarComponent,
    CustomButtonComponent,
    FeedbackHeaderDividerComponent,
    FeedbackHeaderIconComponent,
    FeedbackMessageTitleComponent,
    FeedbackMessageBodyComponent,
    DiscountCouponFormComponent,
    UnwrapHTMLPipe,
    PriceWithDiscountComponent,
    SubscriptionResumeComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ObtrusiveLoaderModule,
    MatExpansionModule,
    MatRadioModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    NgxMaskModule.forRoot(),
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    CommonModule,
    ObtrusiveLoaderModule,
    MatExpansionModule,
    MatRadioModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    NgxMaskModule,
    UserAvatarComponent,
    MatDialogModule,
    CustomButtonComponent,
    FeedbackHeaderDividerComponent,
    FeedbackHeaderIconComponent,
    FeedbackMessageTitleComponent,
    FeedbackMessageBodyComponent,
    DiscountCouponFormComponent,
    UnwrapHTMLPipe,
    PriceWithDiscountComponent,
    SubscriptionResumeComponent
  ]
})
export class SharedModule { }
