import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material';

import { Subscription } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { Country } from 'app/order/models/order.model';
import { BillingFormModel, documentFieldTypesMapping } from 'app/order/models';

import { CepService, UserService } from 'app/utilities/services';

import { CheckoutStoreService } from 'app/order/services/checkout-store.service';
import { OriginDataService } from 'app/utilities/services/origin-data.service';
import { SigninDialogComponent } from 'app/dialogs/signin-dialog/signin-dialog.component';
import { GoogleTagManagerService } from 'app/utilities/services/google-tag-manager.service';
import { DataLayerOrderService } from 'app/utilities/services/data-layer-order.service';
import { SubscriptionService } from 'app/subscription/services/subscription.service';
import { OrderService } from 'app/order/services/order.service';

@Component({
  selector: 'uh-billing-info',
  templateUrl: './billing-info.component.html'
})
export class BillingInfoComponent implements OnInit, OnDestroy {
  countries: Country[] = [];

  billingdata: BillingFormModel;

  documenttypes = Object.keys(documentFieldTypesMapping);

  documenttypesconfig = documentFieldTypesMapping;

  subs = new Subscription();

  isCheckingUserEmail = false;

  @ViewChild('f') form: NgForm;

  get emptyBillingModel(): BillingFormModel {
    return new BillingFormModel();
  }

  get fromStoredModel(): BillingFormModel {
    return new BillingFormModel(this.cs.get('billingData').data);
  }

  set state(value) {
    this.billingdata.state =
      this.billingdata.state !== '' ? this.billingdata.state : value;
  }

  set city(value) {
    this.billingdata.city =
      this.billingdata.city !== '' ? this.billingdata.city : value;
  }

  set line1(value) {
    this.billingdata.line1 =
      this.billingdata.line1 !== '' ? this.billingdata.line1 : value;
  }

  get origindata() {
    return this.ors.getOriginData();
  }

  get loggeduser() {
    return this.us.user;
  }

  get isanonymous() {
    return this.loggeduser.username === 'anonymous';
  }

  get isbrazilian() {
    return this.billingdata.country === 'BR';
  }

  get orderid() {
    return this.ors.getOriginData().orderid;
  }

  constructor(
    private cepService: CepService,
    private cs: CheckoutStoreService,
    private ors: OriginDataService,
    private us: UserService,
    private ds: MatDialog,
    private gtms: GoogleTagManagerService,
    private ods: OrderService,

  ) {
    //this.gtms.dispatchInitiateCheckoutFBPixel();

    this.billingdata = this.fromStoredModel || this.emptyBillingModel;

    this.us.getCountries().subscribe(
      res => (this.countries = res.dict.countries)
    );
  }

  ngOnInit() {
    this.subs.add(
      this.form.valueChanges.subscribe(() =>
        this.cs.set('billingData', {
          data: this.form.value,
          valid: this.form.valid
        })
      )
    );

    if (!this.isanonymous) {
      this.populateWithLoggedUserInfo();
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  populateWithLoggedUserInfo() {
    this.billingdata.fullname = this.loggeduser.fullname;
    this.billingdata.username = this.loggeduser.username;
    this.billingdata.email = this.loggeduser.email;
    this.billingdata.emailconfirm = this.loggeduser.email;

    if (this.loggeduser.addresses && this.loggeduser.addresses.length > 0) {
      const useraddress = this.loggeduser.addresses[0];
      this.billingdata.city = useraddress.city;
      this.billingdata.country = useraddress.country;
      this.billingdata.line1 = useraddress.line1;

      if (useraddress.line2 != null && useraddress.line2 !== '') {
        this.billingdata.line2 = useraddress.line2;
      }

      this.billingdata.neighborhood = useraddress.neighborhood;
      this.billingdata.zipcode = useraddress.zipcode;
      this.billingdata.number = useraddress.number;

      this.checkCep(useraddress.zipcode);
    }

    if (this.loggeduser.documents && this.loggeduser.documents.length > 0) {
      const userdocument = this.loggeduser.documents[0];
      this.billingdata.document = userdocument.text;
      this.billingdata.documenttype = userdocument.documenttype.internalname;
    }

    this.gtms.dispatchGenerateLead(this.billingdata, this.ods.order.orderproducts[0]);

  }

  checkCep(cep) {
    this.cepService.getAddressByCep(cep).subscribe(
      address => {
        this.state = this.state || address.uf;
        this.city = this.city || address.localidade;
        this.billingdata.line1 = this.billingdata.line1 || address.logradouro;
      },
      err => this.resetCEP(this.form.controls['zipcode'])
    );
  }

  resetCEP(cepcontrol) {
    cepcontrol.control.setValue('');
    cepcontrol.control.markAsUntouched();
    cepcontrol.control.markAsPristine();
  }

  checkUserEmailExists(email: string) {
    this.isCheckingUserEmail = true;

    const dialogData = { email: this.billingdata.email, orderid: this.orderid };

    this.us
      .checkUserByEmail(email)
      .pipe(
        switchMap(() => this.openUserEmailExistsDialog(dialogData)),
        take(1)
      )
      .subscribe(
        data => {
          this.isCheckingUserEmail = false;

          if (data) {
            this.populateWithLoggedUserInfo();
          } else {
            this.resetUsernameAndEmail();
          }
        },

        err => (this.isCheckingUserEmail = false)
      );
  }

  resetUsernameAndEmail() {
    this.form.controls['username'].reset();
    this.form.controls['email'].reset();
    this.form.controls['emailconfirm'].reset();
  }

  openUserEmailExistsDialog(data: { [key: string]: any }) {
    return this.ds
      .open(SigninDialogComponent, {
        data: { ...data, orbutton: 'Continuar com e-mail diferente' },
        disableClose: true
      })
      .afterClosed();
  }

  openLoginDialog(evt, data) {
    evt.preventDefault(); // Prevent Anchor link navigation

    const afterClosed = this.ds
      .open(SigninDialogComponent, {
        data: { ...data, orderid: this.orderid }
      })
      .afterClosed();

    afterClosed
      .pipe(take(1))
      .subscribe(
        res => res != null && res && this.populateWithLoggedUserInfo()
      );
  }
}
