import { Component, OnInit } from '@angular/core';

@Component({
    templateUrl: './menu-content.component.html'
})
export class MenuContentComponent implements OnInit {
    constructor() { }

    ngOnInit(): void { }
}
